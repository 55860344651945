import { Module } from 'vuex';

export const asyncStore: Module<any, any> = {
  state: {
    taskRunning: false,
    taskStatus: '',
    taskName: '',
    taskId: null,
    taskPendingString: '',
    percentageComplete: 0,
    count: 0,
    overlay: false
  },
  getters: {
    taskId: (state) => state.taskId,
    taskStatus: (state) => state.taskStatus,
    taskPendingString: (state) => state.taskPendingString,
    taskName: (state) => state.taskName,
    taskOverlay: (state) => state.overlay,
    taskRunning: (state) => state.taskRunning,
    taskPercentageComplete: (state) => state.percentageComplete,
    taskCount: (state) => state.count,
  },
  mutations: {
    initAsync(state) {
      state.taskRunning = false;
      state.taskStatus = '';
      state.taskName = '';
      state.taskId = null;
      state.taskPendingString = '';
      state.percentageComplete = 0;
      state.count = 0;
      state.overlay =  false;
    },
    setAsync(state, data) {
      state.taskRunning = true;
      state.taskStatus = data.taskStatus;
      state.taskName = data.taskName;
      state.taskId = data.taskId;
      state.taskPendingString = data.taskPendingString;
      state.percentageComplete = data.percentageComplete;
      state.count = data.count;
      state.overlay = data.overlay || false;
    },
    setStatus(state, data) {
      state.taskStatus = data;
    },
    setRunning(state, data) {
      state.taskRunning = data;
    },
    setPercentage(state, data) {
      state.percentageComplete = data;
    }
  }
};
